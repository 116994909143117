var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"id":"'breadcrumb0","to":{ name: 'AgentList' }}},[_c('span',{attrs:{"to":""}},[_vm._v(_vm._s(_vm.$t("MANAGEMENT.MANAGEMENT")))])])],1),_c('li',{staticClass:"breadcrumb-item"},[(_vm.role == 'Company')?_c('span',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.SHAREHOLDER")))]):_c('span',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.MEMBERS_AGENTS")))])])])]),_c('div',[(_vm.role == 'Company')?_c('h1',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.SHAREHOLDER")))]):_c('h1',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.MEMBERS_AGENTS")))]),(!_vm.dataLoading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-sm-6"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},_vm._l((_vm.$store.state.agentPages),function(t,index){return _c('li',{key:index,staticClass:"breadcrumb-item"},[(index == 0)?_c('router-link',{attrs:{"id":'breadcrumb' + index,"to":{ name: 'AgentList' }}},[_c('a',{staticClass:"linkNav bold",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.goBack(t.id, t.role)}}},[_vm._v(_vm._s(t.id))])]):(
                      index > 0 && index < _vm.$store.state.agentPages.length - 1
                    )?_c('router-link',{attrs:{"id":'breadcrumb' + index,"to":{ name: 'AgentList', query: { id: t.id } }}},[_c('a',{staticClass:"linkNav bold",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.goBack(t.id, t.role)}}},[_vm._v(_vm._s(t.id))])]):_c('a',[_c('a',{staticClass:"bold",staticStyle:{"color":"#cfa137","text-decoration":"underline"},attrs:{"id":'breadcrumb' + index}},[_vm._v(_vm._s(t.id))])])],1)}),0)])]),_c('div',{staticClass:"col-sm-6"},[(_vm.paginateShow)?_c('paginate',{attrs:{"page-count":_vm.pageSize,"page-range":3,"prev-text":'<<',"next-text":'>>',"click-handler":_vm.clickCallback,"container-class":'pagination float-right',"prev-class":'inline',"next-class":'inline',"page-class":'inline'}}):_vm._e()],1)]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered table-hover"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.NO")))]),_c('th',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.POSITION")))]),_c('th',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.LOGIN_NAME")))]),_c('th',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.NAME")))]),_c('th',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.$t("MANAGEMENT.CREDIT"))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.FIGHT_PT")))]),_c('th',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.PHONE")))]),(
                    _vm.$store.state.agentPages.length == 1 &&
                    (_vm.subAccountMenus.agent || _vm.mainUser)
                  )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("MANAGEMENT.EDIT"))+" ")]):_vm._e(),_c('th',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.STATUS")))]),_c('th',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.BUTTON.SUSPEND")))]),_c('th',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.BUTTON.LOCK")))]),_c('th',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.CURRENCY")))]),_c('th',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.LAST_LOGIN_DATE")))]),_c('th',[_vm._v(_vm._s(_vm.$t("MANAGEMENT.LAST_LOGIN_IP")))])])]),(_vm.dataLoading)?_c('tbody',[_vm._m(0)]):_c('tbody',[(_vm.agentData.length == 0)?_c('tr',[_c('td',{attrs:{"colspan":"11"}},[_vm._v(_vm._s(_vm.$t("DATA_NOT_FOUND")))])]):_vm._l((_vm.agentData.metadata),function(r){return _c('tr',{key:r.id,class:{ inactive: r.status === 'INACTIVE' }},[_c('td',[_vm._v(_vm._s(r.no + 1))]),_c('td',[_c('span',[_vm._v(_vm._s(r.roles[0]))]),(
                      r.royaltySetting.typeBusiness &&
                      r.roles[0] == 'Shareholder'
                    )?_c('span',[_vm._v(" ("+_vm._s(r.royaltySetting.typeBusiness)+")")]):_vm._e()]),_c('td',[(r.roles[0] == 'Member')?_c('span',[_vm._v(_vm._s(r.username))]):_c('router-link',{staticClass:"overflow",attrs:{"to":{ name: 'AgentList', query: { id: r.username } }}},[_c('span',{staticClass:"link",on:{"click":function($event){return _vm.getAgentById(r.username, r.roles[0])}}},[_vm._v(_vm._s(r.username))])])],1),_c('td',[_vm._v(_vm._s(r.name))]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm._f("currency")(r.creditLimit ? r.creditLimit : 0))+" ")]),_c('td',[_c('span',[_vm._v(_vm._s(r.royaltySetting.products[0].rate.THB)+"%")])]),_c('td',[(
                      Object.prototype.hasOwnProperty.call(r, 'information')
                    )?_c('span',[_vm._v(_vm._s(r.information.contact.phone))]):_vm._e()]),(
                    _vm.$store.state.agentPages.length === 1 &&
                    (_vm.subAccountMenus.agent || _vm.mainUser)
                  )?_c('td',[(r.roles[0] == 'Member')?_c('router-link',{attrs:{"to":{ name: 'EditMember', query: { id: r.username } }}},[_c('i',{staticClass:"fas fa-pencil-alt"})]):_c('router-link',{attrs:{"to":{ name: 'EditAgent', query: { id: r.username } }}},[_c('i',{staticClass:"fas fa-pencil-alt"})])],1):_vm._e(),_c('td',[_vm._v(" "+_vm._s(r.active == true ? _vm.$t("MANAGEMENT.BUTTON.ACTIVE") : _vm.$t("MANAGEMENT.BUTTON.LOCK"))+" ")]),_c('td',[_vm._v(_vm._s(r.suspend == true ? _vm.$t("YES") : _vm.$t("NO")))]),_c('td',[_vm._v(_vm._s(r.lock == true ? _vm.$t("YES") : _vm.$t("NO")))]),_c('td',[_vm._v(_vm._s("THB"))]),_c('td',[_vm._v(_vm._s(_vm._f("dateTime")(r.lastLogin)))]),_c('td',[_vm._v(_vm._s(r.ipAddress))])])})],2)])])])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":"7"}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])])}]

export { render, staticRenderFns }